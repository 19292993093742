$(function(){
    $("a",".font-change").on("click", toolsChangeFont);
    $("a",".color-change").on("click", toolsChangeColor);
})

function toolsChangeFont(e){
    $("body").removeClass("scheme_size_small");
    $("body").removeClass("scheme_size_normal");
    $("body").removeClass("scheme_size_big");
    $("body").addClass("scheme_size_"+$(e.target).attr("mode"));

    $("a", $(e.target).parent()).removeClass("sel");
    $(e.target).addClass("sel");

    $.cookie("scheme_font", $(e.target).attr("mode"), {expires:99999, path:"/"});

    return false;
}

function toolsChangeColor(e){
    $("body").removeClass("scheme_color_normal");
    $("body").removeClass("scheme_color_black");
    $("body").removeClass("scheme_color_blue");
    $("body").addClass("scheme_color_"+$(e.target).attr("mode"));

    $("a", $(e.target).parent()).removeClass("sel");
    $(e.target).addClass("sel");

    $.cookie("scheme_color", $(e.target).attr("mode"), {expires:99999, path:"/"});

    return false;
}